import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name:'HomeView',
        component:() => import('../views/HomeView.vue'),
        meta:{title:'济南市长清区邦客堂教育培训学校有限公司',requiresAuth:false}
    },{
        path:'/gywm',
        name:'liveIndex',
        component:() => import('../views/lists/liveStreaming/index.vue'),
        meta:{title:'济南市长清区邦客堂教育培训学校有限公司',requiresAuth:false}
    },{
        path:'/ywjs',
        name:'courseIndex',
        component:() => import('../views/lists/courses/index.vue'),
        meta:{title:'济南市长清区邦客堂教育培训学校有限公司',requiresAuth:false}
    },{
        path:'/cpzs',
        name:'teacherIndex',
        component:() => import('../views/lists/teachers/index.vue'),
        meta:{title:'济南市长清区邦客堂教育培训学校有限公司',requiresAuth:false}
    },{
        path:'/lxwm',
        name:'download',
        component:() => import('../views/lists/download/index.vue'),
        meta:{title:'济南市长清区邦客堂教育培训学校有限公司',requiresAuth:false}
    }
    
]

const router = new VueRouter({
    // mode:'history',
    base:process.env.BASE_URL,
    routes
})

export default router